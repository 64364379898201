import { useState } from "react";

import { PrismicPreview } from "@prismicio/next";
import { PrismicProvider } from "@prismicio/react";
import { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { AppProps } from "next/app";
import Link from "next/link";
import { useRouter } from "next/router";
import { DefaultSeo } from "next-seo";
import NextNProgress from "nextjs-progressbar";

import "@/styles/globals.css";

import queryClientConfig from "@/lib-client/react-query/QueryConfig";
import { Database } from "@/types/supabase";

import { repositoryName } from "../prismicio";
import Script from "next/script";

function MyApp({
  Component,
  pageProps,
}: AppProps & { pageProps: { initialSession: any } }) {
  const router = useRouter();

  //declare once, prevent rerender
  const [supabaseClient] = useState(() => createPagesBrowserClient<Database>());
  const [queryClient] = useState(() => new QueryClient(queryClientConfig));
  return (
    <PrismicProvider
      internalLinkComponent={({ href, children, ...props }) => (
        <Link href={href} {...props}>
          {children}
        </Link>
      )}
    >
      {/* <PrismicPreview repositoryName={repositoryName}> */}
      <SessionContextProvider
        supabaseClient={supabaseClient}
        initialSession={pageProps.initialSession}
      >
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            {/* <Auth.UserContextProvider supabaseClient={supabase}>
              <UserProvider supabaseClient={supabaseClient} autoRefreshToken> */}
            {/* <ClientUserContextProvider supabaseClient={supabaseClient}> */}
            <DefaultSeo
              defaultTitle="NPIQ • Full Online IQ Test • Accurate Result + Report"
              titleTemplate="%s | NPIQ • Full Online IQ Test • Accurate Result + Report"
              description=""
              openGraph={{
                type: "website",
                locale: "en_IE",
                url: "https://www.npiqtest.com/",
                siteName: "NPIQ Test",
              }}
            />
            <NextNProgress color="#ffc542" height={2} />

            {/* <Analytics /> */}
            <Component key={router.asPath} {...pageProps} />
            {/* </ClientUserContextProvider> */}
            {/* </UserProvider> */}
            {/* {/* </Auth.UserContextProvider> */}
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          </Hydrate>
        </QueryClientProvider>
      </SessionContextProvider>
      {/* </PrismicPreview> */}
    </PrismicProvider>
  );
}

export default MyApp;
